import cogoToast from 'cogo-toast';
import React, { useState } from 'react'
import api from '../lib/api';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
   const [email, setEmail] = useState('')
   const navigate = useNavigate()
   const { mutate } = useMutation({
      mutationFn: (data) => api.post("/auth/forgot-password", data),

      onError: (data, error, variables, context) => {
         // An error happened!

         cogoToast.error(data?.response?.data?.message);
         console.log(`rolling back optimistic update with id ${context.id}`);
      },
      onSuccess: (data, variables, context) => {
         console.log("variable", variables);
         if (data.data.status == 200) {
            cogoToast.success("email verified redirecting in 5 s");

            setTimeout(() => {

               navigate(`/reset-password`, { state: { email } })
            }, 5000)
         }
      },

   });


   const handleSubmit = (e) => {
      e.preventDefault()

      if (!email) {
         cogoToast.error('Enter email')
      } else {

         mutate({
            email: email
         })
      }

   }


   return (

      <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 px-md-0">
               <div className="px-lg-5 px-md-3 mt-4">
                  <img src="/images/logo.svg" alt="logo" />
               </div>

               <div className="row justify-content-center mt-4 mx-0">
                  <div className="col-xl-8 col-lg-10 col-md-8">
                     <div className="card border-0 common">
                        <div className="card-body">
                           <h2 className="text-black">Forgot Password</h2>
                           <p className="text-mute my-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                           <form onSubmit={handleSubmit} >
                              <div className="mb-5">
                                 <label htmlFOr="email" className="form-label fw-bolder text-label">Email</label>
                                 <input type="email" onChange={(event) => {
                                    setEmail(event.target.value)
                                 }} className="form-control" placeholder="Enter Email" id="email" />
                              </div>
                              <a href="#">
                                 <button type="submit" className="btn btn-login btn-block w-100">Send</button>
                              </a>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="col-lg-6 px-0 d-None">
               <div className="bg-login">
                  <img src="/images/forgot.png" alt="login" className="img-fluid w-75" />
               </div>
            </div>
         </div>
      </div>



   )
}

export default ForgotPassword