import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  // baseURL: "http://localhost:5000/api/v1/admin",
  // credentials: 'include',
  baseURL: "https://neighborhub-api.alcax.com/api/v1/admin",
  withCredentials: true

});
export const imagePath = "http://localhost:5000/images";





api.interceptors.request.use(
  (config) => {
    // const token = Cookies.get("neighbour_hub_super_admin_token");
    // if (token) {
    //   config.headers["Authorization"] = token;
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("neighbour_hub_super_admin");
      Cookies.remove("neighbour_hub_super_admin_token");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);











export default api;
