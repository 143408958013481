import React, { useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import api from '../lib/api'
import { useNavBar } from '../hooks/useNavBarContext'

const NavBar = () => {
    const { user, logout } = useAuth()
    const { community, setCommunity } = useNavBar()

    const location = useLocation()
    const { data: getCommunities } = useQuery({
        queryKey: ["get-community"],
        queryFn: () => api.get("common/get-community"),
        select: (res) => res.data.data.result,
    });


    console.log('community value', community)



    return (
        <>

            <nav className="navbar navbar-expand-md bg-white sticky-top">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <form className="d-flex" role="search">
                        <div className="ref-image">
                            <div className="input-group br">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="input-group-text bg-white  border-0"><img src="/images/search.svg" alt="search" /></span>
                            </div>
                        </div>
                    </form>
                    {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto">

                            {
                                location.pathname == "/community-member" ? (
                                    <li className="nav-item">
                                        <select name="" id="community" className="community-dropdown form-check" value={community} onChange={(e) => {
                                            setCommunity(e.target.value)
                                        }} >

                                            <option value="#">Select Community</option>

                                            {
                                                getCommunities?.map((item) => {
                                                    return (

                                                        <option value={item._id}>{item.name}</option>

                                                    )


                                                })


                                            }


                                        </select>
                                    </li>
                                ) : (

                                    <></>


                                )

                            }

                        </ul>
                    </div> */}
                </div>
            </nav>



        </>
    )
}

export default NavBar