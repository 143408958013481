import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import api from '../../lib/api';
import TablePagination from '../../component/TablePagination';
import AddGlobalAnnouncements from './components/AddGlobalAnnouncements';
import dayjs from 'dayjs';

const GlobalAnnouncements = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [addOpen, setAddOpen] = useState(false)

    const { data: data, refetch } = useQuery({
        queryKey: ["get-global-announcements"],
        queryFn: () => api.get("web/get-global-announcements"),
        select: (res) => res.data.data,
    });


    return (
        <>
            {
                addOpen ? <AddGlobalAnnouncements show={addOpen} setShow={setAddOpen} refetch={refetch} /> : <></>

            }

            <main className="content">
                <div>
                    {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a> */}
                    <div className="container mt-5 px-44">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-slate mb-4"> Global Announcements</h4>
                            </div>
                            <div className="col-md-12">
                                <div className="card border-0 table-card">
                                    <div className="card-body">
                                        <div className="clearfix mb-4 mt-2">
                                            <div className="float-start">
                                                <h4 className="thm-color">Annoucement List</h4>
                                            </div>
                                            <div className="float-end">
                                                <button className="btn btn-main me-2" onClick={() => {
                                                    setAddOpen(true)
                                                }}>Add Announcement</button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped mb-0 align-middle common-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S no.</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Message</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.result?.map((item, index) => {
                                                            return (

                                                                <tr key={item._id} >
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td> {dayjs(item?.dateAndTime).format('DD-MM-YYYY')}</td>
                                                                    <td>{item?.title}</td>
                                                                    <td>{item?.message}</td>


                                                                    <td style={{ display: 'flex' }}>
                                                                        <button className="btn btn-send me-2" onClick={() => {


                                                                        }} >Send</button>






                                                                    </td>

                                                                </tr>


                                                            )


                                                        })

                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <TablePagination totalPages={data?.paginate?.totalPages} page={page} setPage={setPage} />

                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>




        </>
    )
}

export default GlobalAnnouncements