import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "./useCookies";
import { useEffect } from "react";

const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
    const [cookie, setCookie] = useCookies("neighbour_hub_super_admin_token", null);
    const [community, setCommunity] = useState('#')
    const navigate = useNavigate();


    const value = useMemo(
        () => ({
            community, setCommunity
        }),
        [community]
    );

    return <NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>;
};

export const useNavBar = () => {
    return useContext(NavBarContext);
};
