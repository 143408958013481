import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import api from '../../../lib/api';

import cogoToast from 'cogo-toast';
import AddTermsAndConditions from './components/AddTermsAndConditions';
import EditTermsAndConditions from './components/EditTermsAndConditions';
import { Form } from 'react-bootstrap';

const TermsAndConditions = () => {

    const [editOpen, setEditOpen] = useState(false)
    const [addOpen, setAddOpen] = useState(false)

    const [editData, setEditData] = useState({})
    const { data: data, refetch } = useQuery({
        queryKey: ["terms-and-conditions"],
        queryFn: () => api.get("web/terms-and-conditions"),
        select: (res) => res.data.data,
    });




    const { mutate: deleteTermsAndConditions } = useMutation({
        mutationFn: (data) => api.post("/web/delete-terms-and-conditions", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            if (data.data.status == 200) {
                cogoToast.success(`${data.data.message}`);
                refetch()

            }
        },

    });


    const { mutate: toggle } = useMutation({
        mutationFn: (data) => api.post("/web/toggle-terms-and-conditions", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            if (data.data.status == 200) {
                cogoToast.success(`${data.data.message}`);
                refetch()

            }
        },

    });





    return (
        <main className="content">
            {
                editOpen ? <EditTermsAndConditions show={editOpen} setShow={setEditOpen} editData={editData} refetch={refetch} /> : <></>

            }

            {
                addOpen ? <AddTermsAndConditions show={addOpen} setShow={setAddOpen} refetch={refetch} /> : <></>

            }


            <div>
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                <div className="container mt-5 px-44">
                    <div className="row">

                        <div className="col-md-12">

                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                    <div className="card border-0 table-card">
                                        <div className="card-body">
                                            <div className="clearfix mb-4 mt-2">
                                                <div className="float-start">
                                                    <h4 className="thm-color">Terms And Conditions</h4>
                                                </div>
                                                <div className="float-end">
                                                    <div className="d-flex">
                                                        {/* <button className="btn btn-main me-2" onClick={() => {
                                                            setAddOpen(true)
                                                        }} >Add Terms and Conditions</button> */}
                                                        {/* <button className="btn btn-pink">
                        <img src="/images/export.svg" alt="images" className="img-fluid" />
                        Export</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 align-middle common-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S no.</th>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data?.map((item, index) => {
                                                                return (

                                                                    <tr key={item._id} >
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{item?.title}</td>
                                                                        <td>{item?.description}</td>
                                                                        <td style={{ display: 'flex' }}>
                                                                            <button className="btn btn-green me-3" onClick={() => {
                                                                                setEditOpen(true)
                                                                                setEditData(item)

                                                                            }} ><img src="/images/edit.svg" alt="edit" className="img-fluid" /></button>

                                                                            {/* <button className="btn btn-red me-3" onClick={() => {
                                                                                deleteTermsAndConditions({ id: item._id })
                                                                            }} ><img src="/images/delete.svg" alt="delete" className="img-fluid" /></button> */}

                                                                            <Form.Check
                                                                                onChange={(e) => {
                                                                                    toggle({
                                                                                        id: item._id,
                                                                                        status: !item.isActive

                                                                                    })
                                                                                }}
                                                                                checked={item.isActive}
                                                                                type="switch"
                                                                                id="custom-switch"
                                                                                label={`${item.isActive ? 'InActive' : 'Active'}`}

                                                                            // label="Check this switch"
                                                                            />




                                                                        </td>
                                                                        {/* <td>
                                      </td> */}
                                                                    </tr>


                                                                )


                                                            })

                                                        }

                                                    </tbody>
                                                    <tfoot>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default TermsAndConditions