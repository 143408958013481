import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import { Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import PhoneInput from 'react-phone-input-2'

const EditHoa = ({ show, setShow, editData }) => {


  const [code, setCode] = useState('')
  const [phone, setPhone] = useState('')
  const [flag, setFlag] = useState('')



  const handleClose = () => {

    setShow(false)

  }



  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm()



  const { mutate } = useMutation({
    mutationFn: (data) => api.post("/web/edit-hoa", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      if (data.data.status == 200) {
        cogoToast.success(`${data?.data?.message}`);
        setTimeout(() => {
          setShow(false)
        }, 1000)

      }
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });



  useEffect(() => {
    if (Object.values(editData)?.length > 0) {
      setValue("name", editData?.name)
      setValue("email", editData?.email)
      setValue("phone", editData?.phone)
      setValue("address1", editData?.address?.address_line_1)
      setValue("address2", editData?.address?.address_line_2)
      setValue("state", editData?.address?.state)
      setValue("city", editData?.address?.city)
      setValue("zipCode", editData?.address?.zip)
      setPhone(editData?.dialCode?.slice(1) + editData?.phone)
      setCode(editData?.dialCode?.slice(1))
      setFlag(editData?.flag)


    }


  }, [editData])








  const onSubmit = (data) => {
    if (!phone) {
      cogoToast.error('Mobile number is not entered')
      return
    }
    let dialCode = code
    let withoutCodeNumber = phone.slice(code.length)


    const body = {
      id: editData._id,
      name: data.name,
      email: data.email,
      phone: withoutCodeNumber,
      dialCode: `+${dialCode}`,
      flag: flag,
      address: {
        address_line_1: data.address1,
        address_line_2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zipCode,


      }



    }


    mutate(body)
  }




  return (

    <Modal show={show} onHide={handleClose}>

      <Modal.Body>
        <main className="content">
          <div>
            {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
            <div className="container px-44">
              <div className="row">

                <div className="col-xl-12">
                  <div className="card border-0 py-4 px-2">
                    <h4 className="text-slate mb-3">Edit HOA</h4>
                    <div className="card-body  px-0">
                      <form className="common-form" onSubmit={handleSubmit(onSubmit)} >

                        <div className="mb-4">
                          <label className="form-label">H.O.A Name</label>
                          <input type="text" className="form-control" placeholder="Enter H.O.A Name" {...register("name", { required: "Name is required" })} />
                          {errors.name && <p style={{ color: 'red' }} role="alert">{errors.name.message}</p>}

                        </div>
                        <div className="mb-4">
                          <label className="form-label">Email</label>
                          <input type="text" className="form-control" placeholder="Enter Email" {...register("email", { required: "Email is required" })} />
                          {errors.email && <p style={{ color: 'red' }} role="alert">{errors.email.message}</p>}

                        </div>
                        <div className="mb-4">
                          <label className="form-label">Phone</label>

                          <PhoneInput
                            country={'in'}
                            value={phone}
                            onChange={(value, country) => {
                              setCode(country.dialCode)

                              setPhone(value)
                              setFlag(country.name)
                            }}
                            enableSearch={true}
                          />



                        </div>



                        <div className="col-md-12">
                          <div className="mb-4">
                            <label className="form-label">Address</label>
                            <input type="text" className="form-control mb-4" placeholder="Enter Address 1" {...register("address1", { required: "Address Line 1 is required" })} />

                            {errors.address1 && <p style={{ color: 'red' }} role="alert">{errors.address1.message}</p>}

                            <input type="text" className="form-control mb-4" placeholder="Enter Address 2" {...register("address2")} />

                            {errors.address2 && <p style={{ color: 'red' }} role="alert">{errors.address2.message}</p>}


                            <input type="text" className="form-control mb-4" placeholder="State" {...register("state", { required: "state is required" })} />
                            {errors.state && <p style={{ color: 'red' }} role="alert">{errors.state.message}</p>}

                            <input type="text" className="form-control mb-4" placeholder="City" {...register("city", { required: "city is required" })} />
                            {errors.city && <p style={{ color: 'red' }} role="alert">{errors.city.message}</p>}

                            <input type="text" className="form-control mb-4" placeholder="Zip Code" {...register("zipCode", { required: "city is required" })} />
                            {errors.zipCode && <p style={{ color: 'red' }} role="alert">{errors.zipCode.message}</p>}


                          </div>
                        </div>




                        <button type="submit" className="btn btn-main Shadow">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Modal.Body>

    </Modal>


  )
}

export default EditHoa