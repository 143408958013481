import "bootstrap/dist/css/bootstrap.min.css";
import 'react-phone-input-2/lib/style.css'

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HomeLayout from "./component/Layout";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import CommunityMemberManagement from "./pages/CommunityMemberManagement/CommunityMemberManagement";
import AddUsers from "./pages/CommunityMemberManagement/components/AddUsers";
import SideBarTemp from "./pages/SideBarTemp";
import AboutUs from "./pages/staticPages/AboutUs";
import PrivacyPolicy from "./pages/staticPages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/staticPages/TermsAndConditions.js/TermsAndConditions";
import GlobalAnnouncements from "./pages/GlobalAnnouncements/GlobalAnnouncements";
import CommunityTickets from "./pages/HelpSection/CommunityTickets";
import UserTickets from "./pages/HelpSection/UserTickets";
import HoaManagement from "./pages/HoaManagement/HoaManagement";

function App() {
  //   useEffect(() => {
  //     window.open("/");
  //   });

  return (
    <>
      <Routes>
        <Route element={<Register />} path="/register" />
        <Route element={<Login />} path="/login" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<ResetPassword />} path="/reset-password" />


        <Route element={<HomeLayout />}>
          {/* <Route element={<SideBarTemp />} path="/" /> */}

          <Route element={<Dashboard />} path="/" />
          <Route element={<CommunityMemberManagement />} path="/community-member" />
          <Route element={<HoaManagement />} path="/hoa-management" />
          <Route element={<AddUsers />} path="/add-users" />
          <Route element={<AboutUs />} path="/aboutUs" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<TermsAndConditions />} path="/terms-and-conditions" />
          <Route element={<CommunityTickets />} path="/get-community-tickets" />
          <Route element={<UserTickets />} path="/get-user-tickets" />

          <Route element={<GlobalAnnouncements />} path="/global-announcements" />






        </Route>
      </Routes>
    </>
  );
}

export default App;
